import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscribe } from '../core/dataObjects/subscribe';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  getViewersList(): Observable<any> {
    return this.http.get('./assets/viewers.json');
  }

  sendEmail(subscribe: Subscribe): Observable<Object> {
    return this.http.post('/events', subscribe);
  }
  
}
