import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.css']
})
export class ConfirmUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.redirectUserToLogin();
  }

  redirectUserToLogin() {
    var intervalId = window.setInterval(function () {
      window.location.href = environment.domain_name + '/Login/#/login'
    }, 5000);
  }
}
