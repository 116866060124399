// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*Production*/
  api_base_url: 'https://api.zipgigz.com',
  domain_name: 'https://web.zipgigz.com'

  /*Demo*/
  // api_base_url: 'https://demoapi.zipgigz.com',
  // domain_name: 'https://demo.zipgigz.com'

  /*UAT*/
  // api_base_url: 'https://uatapi.zipgigz.com',
  // domain_name: 'https://uat.zipgigz.com'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
