import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  getReviewById(req): Observable<any> {
    return this.http.post<any>('/reviews/getReviewById', req);
  }
  
  submitFeedbackFormData(req): Observable<any> {
    return this.http.post<any>('/reviews/submitFeedbackFormData', req);
  }

}
