import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  id:string;
  deplId:string;
  formSubmitted=false;
  form:FormGroup;
  review;
    days=[0,1,2,3,4,5,6,7];
    feedbackDone;
    feedback;
  constructor(private route:ActivatedRoute, private dashboard: FeedbackService,private _formBuilder:FormBuilder,private router:Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if( params.get('id')){
        this.id = params.get('id');
        this.getReview();
      }
  });
     
  this.form = this._formBuilder.group({
    performance: new FormControl('',Validators.required),
    behavior:new FormControl('',Validators.required),
    attendance:new FormControl('',Validators.required),
    applause:new FormControl(false,Validators.required),
    comments:new FormControl('',[Validators.required]),
    noOfWorkingDays:new FormControl('',Validators.required),
    noOfLeaves:new FormControl('',Validators.required),
  });
  }

  getReview(){
    let req={
      id:this.id
    }
    this.dashboard.getReviewById(req).subscribe(resp=>{
      console.log(resp);
      if(resp.length>0){
        this.feedback=true;
        this.review= resp[0];
        if(resp[0].feedbackDone){
            this.feedbackDone=resp[0].feedbackDone;
        }
      }
      else{
        this.feedback=false
      }
     
    })
  }

  submitFeedback(){
    this.formSubmitted=true;
    let req={
      ...this.review,
      ...this.form.value
    }
    if(this.form.valid){
     
      this.dashboard.submitFeedbackFormData(req).subscribe(resp=>{
        // if(resp.success){
          
        // }
        console.log(resp);
        if(resp.success){
          this.getReview();
          this.router.navigate(['home']);
        }
      })
    }
   
  }
}
