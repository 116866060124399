<header id="home">
    <div class="container">
        <nav class="navbar navbar-expand-lg" style="padding: 0;">
            <a class="navbar-brand" [href]=urlPath>
                <img src="assets/logo.png" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <div class="mr-auto"></div>
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" pageScroll href="#home">Home <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" pageScroll href="#Zipgigz">Why Zipgigz</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" pageScroll href="#Works">How It Works</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" pageScroll href="#Partner">Partner With Us</a>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="btn btn-login" (click)="goToLogin()">Log in</button>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="btn btn-signUp" (click)="goToCommonSignUp()">Sign Up</button>
                    </li>
                </ul>
            </div>
        </nav>

    </div>
    <!-- <div class="container pt-5 section-1">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h2>Tech Talent as a Service</h2>
                <p class="pt-3">IT and Software talent on easy monthly billing</p>
                <p>Efficient candidate match-making through a network<br>of staffing agencies across geographies</p>
                <p>No payroll and compliance hassles</p>
                <button type="button" class="btn btn-getStarted mt-4" (click)="getStarted()">Get Started</button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img class="imgSec-1" src="assets/IT Staffing for Tech skills in India.png">
            </div>

        </div>
    </div> -->
</header>
<section style="background-color: #FBFBFB;">
    <div class="container pt-5 section-1">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h2>Tech Talent as a Service</h2>
                <p class="pt-3">Hire Remote Tech Talent on contract from Top Talent Suppliers</p>
                <p>Choose the skills, experience and billing rates that work for you</p>
                <p>Discover, interview, deploy and track candidate performance. All at one place</p>
                <button type="button" class="btn btn-getStarted mt-4" (click)="goToCompanySignUp()">Hire Tech Talent on contract</button>
                <p class="pt-3">Are you looking to supply Tech talent?</p>
                <p class="rts"  (click)="goToVendorSignup()">Register as a Talent Supplier</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img class="imgSec-1" src="assets/IT Staffing for Tech skills in India.png">
            </div>

        </div>
    </div>
</section>
<div class="section-2" id="Zipgigz">
    <div class="container">
        <h2>Why Zipgigz</h2>
        <div class="row row1">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img src="assets/Easy Tech hiring for short term.svg" class="zipGigz" alt="banner">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <div class="row siderow">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-md-6 ">
                        <div class="card" style="width: 19rem;">
                            <div class="card-body">
                                <img class="card-img-top" src="assets/Dynamic IT temp staffing solutions.svg"><br>
                                <h2 class="card-title">Dynamic workforce</h2><br>
                                <p class="card-text">Quickly scale up/scale <br> down as per your <br> business needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-md-6 ">
                        <div class="card" style="width: 19rem;">
                            <div class="card-body">
                                <img class="card-img-top" src="assets/hire best software developers.svg"><br>
                                <h2 class="card-title">Retain top performers</h2><br>
                                <p class="card-text">Onboard key talent after <br> 6 billing months at <br> no
                                    additional cost.</p>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="row siderow1">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-md-6 ">
                        <div class="card" style="width: 19rem;">
                            <div class="card-body">
                                <img class="card-img-top" src="assets/Fast tech hiring.svg"><br>
                                <h2 class="card-title" style="text-align: justify;">Reduced time and<br> efforts</h2>
                                <br>
                                <p class="card-text">Get up to 10 matching<br>profiles within 48 hours<br>Deploy talent
                                    faster.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-md-6 ">
                        <div class="card" style="width: 19rem;">
                            <div class="card-body">
                                <img class="card-img-top" src="assets/Third party payroll solutions.svg"><br>
                                <h2 class="card-title">No payroll hassles</h2><br>
                                <p class="card-text">We take care of payroll<br>and compliance.You get<br>monthly
                                    invoices.</p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>

    </div>
</div>

<section class="section-3" style="margin-top: 30px;">
    <div class="container">
        <h2>Specialized temp staffing for Tech skill-sets</h2>
        <div id="carouselFullScreen" class="carousel slide pt-5" data-ride="carousel" data-interval="5000">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/1. Bangalore 3.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">DevOps<br>Engineer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Bangalore,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Docker</label>
                                        <label class="userSkills">AWS</label>
                                        <label class="userSkills">CI/CD</label>
                                        <label class="userSkills">Kubernetes</label>
                                        <label class="userSkills">Shell Scripting</label>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/2. New Delhi 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">Android
                                                Kotlin<br>Developer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;New Delhi,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Core Java</label>
                                        <label class="userSkills">Kotlin</label>
                                        <label class="userSkills">HMI</label>
                                        <label class="userSkills">Jetpack</label>
                                        <label class="userSkills">Android Studio</label>

                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/3. Hyderabad 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">Full Stack<br>Developer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">5.5
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Hyderabad,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">JavaScript</label>
                                        <label class="userSkills">jQuery</label>
                                        <label class="userSkills">CSS</label>
                                        <label class="userSkills">HTML</label>
                                        <label class="userSkills">AngularJS</label>

                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/8 Gurugram 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">UI / UX<br>Designer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Gurugram,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">CSS3</label>
                                        <label class="userSkills">HTML5</label>
                                        <label class="userSkills">Media Query</label>
                                        <label class="userSkills">Bootstrap</label>
                                        <label class="userSkills">CSS Flexbox</label>


                                    </div>



                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/6 Chennai 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">Java<br>Developer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Chennai,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Spring</label>
                                        <label class="userSkills">Hibernate</label>
                                        <label class="userSkills">Oracle</label>
                                        <label class="userSkills">AngularJS</label>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="card shadow p-1 mb-5 bg-white rounded">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/7 Mumbai 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text largeScreenLbl">React Native<br>Developer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4.5
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Mumbai,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">SQLite</label>
                                        <label class="userSkills">Javascript</label>
                                        <label class="userSkills">iOS Swift</label>
                                        <label class="userSkills">React Native</label>
                                        <label class="userSkills">ReactJS</label>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselFullScreen" role="button" data-slide="prev">
                <span class="fa fa-angle-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselFullScreen" role="button" data-slide="next">
                <span class="fa fa-angle-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!-- This Carsouel will be displayed for all screens below 1200px -->
        <div id="carouselMediumScreen" class="carousel slide pt-5" data-ride="carousel" data-interval="5000">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row" style="margin: auto;">
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/1. Bangalore 3.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">DevOps Engineer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4
                                                    years</span></label><br>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Bangalore,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Docker</label>
                                        <label class="userSkills">AWS</label>
                                        <label class="userSkills">CI/CD</label>
                                        <label class="userSkills">Kubernetes</label>
                                        <label class="userSkills">Shell Scripting</label>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/2. New Delhi 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">Android Kotlin<br>developer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;New Delhi,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Core Java</label>
                                        <label class="userSkills">Kotlin</label>
                                        <label class="userSkills">HMI</label>
                                        <label class="userSkills">Jetpack</label>
                                        <label class="userSkills">Android Studio</label>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row" style="margin: auto;">
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/3. Hyderabad 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">Full Stack developer</label>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">5.5
                                                    years</span></label>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Hyderabad,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">JavaScript</label>
                                        <label class="userSkills">jQuery</label>
                                        <label class="userSkills">CSS</label>
                                        <label class="userSkills">HTML</label>
                                        <label class="userSkills">AngularJS</label>

                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/8 Gurugram 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">UI/UX Designer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label><br>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Gurugram,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">CSS3</label>
                                        <label class="userSkills">HTML5</label>
                                        <label class="userSkills">Bootstrap</label>
                                        <label class="userSkills">Media Query</label>
                                        <!-- <label class="userSkills">CSS Flexbox</label> -->


                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row" style="margin: auto;">
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/6 Chennai 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">Java Developer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                                    years</span></label><br>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Chennai,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">Spring</label>
                                        <label class="userSkills">Hibernate</label>
                                        <label class="userSkills">Oracle</label>
                                        <label class="userSkills">AngularJS</label>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="card shadow p-1 mb-5 bg-white rounded" style="width: auto;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="carouselImg" src="assets/carousel/7 Mumbai 1.jpg">
                                        </div>
                                        <div class="col-md-8">
                                            <label class="card-text mediumScreenLbl">React Native developer</label><br>
                                            <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4.5
                                                    years</span></label><br>
                                            <label class="lblCity"><i
                                                    class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Mumbai,
                                                    India</span></label>
                                        </div>

                                    </div>
                                    <div style="padding-left: 12px; padding-top: 10px;">
                                        <label class="userSkills">SQLite</label>
                                        <label class="userSkills">Javascript</label>
                                        <label class="userSkills">iOS Swift</label>
                                        <label class="userSkills">React Native</label>
                                        <!-- <label class="userSkills">ReactJS</label> -->
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <a class="carousel-control-prev" href="#carouselMediumScreen" role="button" data-slide="prev">
                <span class="fa fa-angle-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselMediumScreen" role="button" data-slide="next">
                <span class="fa fa-angle-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <!-- This Carsouel will be displayed for all screens below 775px -->
        <div id="carouselSmallScreen" class="carousel slide pt-5" data-ride="carousel" data-interval="5000">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row" style="margin: auto;">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/1. Bangalore 3.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">DevOps engineer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Bangalore,
                                            India</span></label>
                                </div>

                            </div>
                            <div style="padding-left: 12px; padding-top: 10px;">
                                <label class="userSkills">Docker</label>
                                <label class="userSkills">AWS</label>
                                <label class="userSkills">CI/CD</label>
                                <label class="userSkills">Kubernetes</label>
                                <label class="userSkills">Shell Scripting</label>
                            </div>



                        </div>

                    </div>



                </div>
                <div class="carousel-item">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row" style="margin: auto;">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/2. New Delhi 1.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">Android Kotlin developer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;New Delhi,
                                            India</span></label>

                                </div>

                            </div>
                            <div style="padding-left: 5px; padding-top: 10px;">
                                <label class="userSkills">Core Java</label>
                                <label class="userSkills">Kotlin</label>
                                <label class="userSkills">HMI</label>
                                <label class="userSkills">Jetpack</label>
                                <label class="userSkills">Android Studio</label>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row" style="margin: auto;">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/3. Hyderabad 1.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">Full Stack developer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">5.5
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Hyderabad,
                                            India</span></label>
                                </div>

                            </div>
                            <div style="padding-left: 12px; padding-top: 10px;">
                                <label class="userSkills">JavaScript</label>
                                <label class="userSkills">jQuery</label>
                                <label class="userSkills">CSS</label>
                                <label class="userSkills">HTML</label>
                                <label class="userSkills">AngularJS</label>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="carousel-item">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row" style="margin: auto;">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/8 Gurugram 1.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">UI/UX Designer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Gurugram,
                                            India</span></label>
                                </div>

                            </div>
                            <div style="padding-left: 12px; padding-top: 10px;">
                                <label class="userSkills">CSS3</label>
                                <label class="userSkills">HTML5</label>
                                <label class="userSkills">Bootstrap</label>
                                <label class="userSkills">Media Query</label>
                                <!-- <label class="userSkills">CSS Flexbox</label> -->


                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/6 Chennai 1.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">Java developer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">3
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Chennai,
                                            India</span></label>
                                </div>

                            </div>
                            <div style="padding-left: 12px; padding-top: 10px;">
                                <label class="userSkills">Spring</label>
                                <label class="userSkills">Hibernate</label>
                                <label class="userSkills">Oracle</label>
                                <label class="userSkills">AngularJS</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card shadow p-1 mb-5 bg-white rounded">
                        <div class="card-body">
                            <div class="row" style="margin: auto;">
                                <div class="smallScreenCardImg">
                                    <img class="carouselImg" src="assets/carousel/7 Mumbai 1.jpg">
                                </div>
                                <div class="smallScreenLbl">
                                    <label class="card-text">React Native developer</label><br>
                                    <label><span class="lblExp">Experience</span>&nbsp;<span class="years">4.5
                                            years</span></label><br>
                                    <label class="lblCity"><i
                                            class="fa fa-map-marker location"></i><span>&nbsp;&nbsp;Mumbai,
                                            India</span></label>
                                </div>

                            </div>
                            <div style="padding-left: 12px; padding-top: 10px;">
                                <label class="userSkills">SQLite</label>
                                <label class="userSkills">Javascript</label>
                                <label class="userSkills">iOS Swift</label>
                                <label class="userSkills">React Native</label>
                                <!-- <label class="userSkills">ReactJS</label> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselSmallScreen" role="button" data-slide="prev">
                <span class="fa fa-angle-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselSmallScreen" role="button" data-slide="next">
                <span class="fa fa-angle-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <button type="button" class="btn btn-discover" (click)="goToCompanySignUp()">Discover Tech Resources</button>

    </div>

</section>
<div class="section-4" id="Works">
    <h2>How it works</h2>
    <div class="container">
        <diV class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="card">
                    <img class="card-img-top pt-4" src="assets/Specialized staffing.svg" alt="Card image cap">
                    <div class="card-body" style="text-align: center;">
                        <div class="customStepper">1</div>
                        <h2 class="customHeaders">Share your <br> requirement</h2>
                        <p class="card-text">We specialize in sourcing IT/Software talent for temp deployment
                            as per your budget</p>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="card">
                    <img class="card-img-top" src="assets/developer resumes.svg" alt="Card image cap">
                    <div class="card-body" style="text-align: center;">
                        <div class="customStepper">2</div>
                        <h2 class="customHeaders">Receives profiles <br> within 48 hours</h2>
                        <p class="card-text">We email you the best matching candidate profiles along with profile
                            comparisons</p>
                    </div>
                </div>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="card">
                    <img class="card-img-top" src="assets/Online interview of tech talent.svg" alt="Card image cap">
                    <div class="card-body" style="text-align: center;">
                        <div class="customStepper">3</div>
                        <h2 class="customHeaders">Shortlist and <br> interview</h2>
                        <p class="card-text">Candidate available pan India for on-premise as well as remote/work-
                            from-home deployments</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="card">
                    <img class="card-img-top" src="assets/Short term hiring in India.svg" alt="Card image cap">
                    <div class="card-body" style="text-align: center;">
                        <div class="customStepper">4</div>
                        <h2 class="customHeaders">Book for required <br> tenure</h2>
                        <p class="card-text">Flexible tenures starting from 3 months onward.Extend as needed.
                        </p>
                    </div>
                </div>
            </div>
        </diV>

    </div>
</div>
<div class="section-5">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                <h2>Schedule a call with our talent specialist</h2>
                <button type="button" class="btn btn-schedule mt-5 " (click)="getSchedule()">Schedule Call</button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <img style="float: right;" src="assets/Schedule-.svg">
            </div>

        </div>

    </div>
</div>
<div class="section-6">
    <div class="container">
        <div>
            <div id="carousel" class="carousel slide" data-ride="carousel" data-interval="5000">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="card shadow p-3 mb-5 bg-white rounded">
                            <div class="card-body">
                                <div style="display: inline block;">
                                    <span style="float: left;"><i class="fa fa-quote-left"></i></span>
                                    <span style="float: right;"><i class="fa fa-quote-right"></i></span>
                                    <p
                                        style="text-align: justify; text-justify: inter-word; padding-left: 60px; padding-right: 60px;">
                                        Zipgigz provides a good way to manage temporary skill shortage and project
                                        specific talent without payroll hassles.It
                                        helps to handle peak business load in a better manner.
                                    </p>
                                </div>
                                <div id="FullScreenTestimonialsLbl">
                                    <label
                                        style="padding-left: 60px; padding-top: 30px; font-family: zipGigzHeader; color: #67BC60; font-size: 22px;">Jitesh
                                        Nagaria</label><span style="font-size: 25px;">&nbsp;|</span><span
                                        style="font-size: 14px;color: #4B4B4B;font-family: zipGigzContent;">&nbsp;CEO</span><br>
                                    <div>
                                        <label
                                            style="padding-left: 60px; font-size: 22px;font-family:zipGigzContent;font-weight: bold;color:#4B4B4B">doLoopTech</label>
                                    </div>
                                </div>



                                <!-- For small screens -->
                                <div id=testimonialsLbl>
                                    <label
                                        style="padding-left: 60px; padding-top: 30px; font-family: zipGigzHeader; color: #67BC60; font-size: 16px;">Jitesh
                                        Nagaria</label><br>
                                    <label
                                        style="padding-left: 60px;font-size: 16px;color: #4B4B4B;font-family: zipGigzContent;">CEO</label><br>
                                    <label
                                        style="padding-left: 60px; font-size: 16px;font-family:zipGigzContent;font-weight: bold;color:#4B4B4B">doLoopTech</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="card shadow p-3 mb-5 bg-white rounded">
                            <div class="card-body">
                                <div style="display: inline block;">
                                    <span style="float: left;"><i class="fa fa-quote-left"></i></span>
                                    <span style="float: right;"><i class="fa fa-quote-right"></i></span>
                                    <p
                                        style="text-align: justify; text-justify: inter-word; padding-left: 60px; padding-right: 60px;">
                                        They understood the technical requirements well and filtered relevant
                                        profiles. We had to screen and interview only a handful of
                                        them to find our right fit. This freed up significant
                                        bandwidth of my team from the typical time consuming selection process.
                                    </p>
                                </div>
                                <div id="fullScreenTestimonial2">
                                    <label
                                        style="padding-left: 60px;padding-top: 30px; font-family: zipGigzHeader; color: #67BC60; font-size: 22px;">Sachhidanand
                                        Tilay
                                    </label><span style="font-size: 25px;">&nbsp;|</span><span
                                        style="font-size: 14px;color: #4B4B4B;font-family: zipGigzContent;">&nbsp;Technical
                                        Manager</span><br>
                                    <div>
                                        <label
                                            style="padding-left: 60px; font-size: 22px;font-family:zipGigzContent;font-weight: bold;color:#4B4B4B">Olethia
                                            Labs</label>
                                    </div>
                                </div>


                                <!-- For small Screens -->

                                <div id="testimonials2">
                                    <label
                                        style="padding-left: 60px;padding-top: 10px; font-family: zipGigzHeader; color: #67BC60; font-size: 16px;">Sachhidanand
                                        Tilay
                                    </label><br>
                                    <label
                                        style="padding-left: 60px; font-size: 16px;color: #4B4B4B;font-family: zipGigzContent;">Technical
                                        Manager</label><br>
                                    <label
                                        style="padding-left: 60px; font-size: 16px;font-family:zipGigzContent;font-weight: bold; color:#4B4B4B">Olethia
                                        Labs</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="carousel-item ">
                        <div class="card shadow p-3 mb-5 bg-white rounded">
                            <div class="card-body">
                                <div style="display: inline block;">
                                    <span style="float: left;"><i class="fa fa-quote-left"></i></span>
                                    <span style="float: right;"><i class="fa fa-quote-right"></i></span>
                                    <p
                                        style="text-align: justify; text-justify: inter-word; padding-left: 60px; padding-right: 60px;">
                                        Team Zipgigz was prompt enough to suggest several candidates within
                                        a pretty short time. We had enough options to choose from and
                                        it helped us close the positions in time.
                                    </p>
                                </div>
                                <div id="fullScreenTestimonial3">
                                    <label
                                        style="padding-left: 60px;padding-top: 30px; font-family: zipGigzHeader; color: #67BC60; font-size: 22px;">Shraddha
                                        Chavan</label><span style="font-size: 25px;">&nbsp;|</span><span
                                        style="font-size: 14px;color: #4B4B4B;font-family: zipGigzContent;">&nbsp;Senior
                                        Manager -HR</span><br>

                                    <div>
                                        <label
                                            style="padding-left: 60px; font-size: 22px;font-family:zipGigzContent;font-weight: bold;color:#4B4B4B">DEIF
                                            India</label>
                                    </div>
                                </div>

                                <!-- For small Screen -->
                                <div id="testimonials3">
                                    <label
                                        style="padding-left: 60px;padding-top: 30px; font-family: zipGigzHeader; color: #67BC60; font-size: 16px;">Shraddha
                                        Chavan</label><br>
                                    <label
                                        style="padding-left:60px;font-size: 12px;color: #4B4B4B;font-family: zipGigzContent;">Senior
                                        Manager -HR</label><br>
                                    <label
                                        style="padding-left: 60px; font-size: 16px;font-family:zipGigzContent;font-weight: bold;color:#4B4B4B">DEIF
                                        India</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                    <span class="fa fa-angle-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
                    <span class="fa fa-angle-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>

            </div>
        </div>
        <div class="row rowCustom">
            <div class="col-md-6 col-sm-12">
                <img src="assets/hire developers for short term.svg" class="rightResource">

            </div>
            <div class="col-md-6 col-sm-12">
                <h4 style="font-family:zipGigzHeader;margin-top: 150px; float: right; font-size: 26px;color: #4B4B4B;">
                    Find the right resource fit with Zipgigz</h4>
                <button type="button" class="btn btn-started mt-5 " (click)="goToVendorSignup()">Get Started</button>

            </div>

        </div>

    </div>

</div>
<div class="section-7" id="Partner">
    <h2>Got Tech skills? Connect with us</h2>
    <div class="container">
        <div class="row" style="text-align: center;">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img src="assets/Hiring-rafiki.svg">
                <h5 class="pt-5">I am a staffing agency</h5>
                <button type="button" class="btn btn-partner" (click)="goToCommonSignUp()">Partner with us</button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img style="height: 250px; width: auto;" src="assets/I_am_an_IT_Software_professional.svg">
                <h5 style="padding-top: 60px;">I am an IT/Software Professional</h5>
                <button type="button" class="btn btn-register" (click)="registerForGigs()">Register for
                    Gigs</button>

            </div>

        </div>

    </div>

</div>
<div class="section-8">
    <h2>How Zipgigz brings agility in Tech hiring</h2>
    <div class="container">
        <div class="row pt-5">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="text-align: left;
            text-justify: inter-word;">
                <p>
                    <span style="font-size: 18px; font-weight: bold;">IT recruitment is a cakewalk -said no one
                        ever.</span>
                    Variability in business cycles and project specific skill
                    requirements pose several challenges in
                    front of businesses and recruiters when it
                    comes to choosing the right IT staffing
                    strategy for their organisation. No one
                    willingly wants to lose out on opportunity
                    during peak business load, while on the
                    other hand, it is also critical to ensure
                    positive return on investment in IT and
                    software manpower during uncertain
                    business times.
                </p>
                <p>
                    Often there are times when the organisation has short
                    term need for some specific tech
                    skills or when some key projects come under way and
                    require quick scale up of the tech workforce.
                    The slow and arduous process of permanent recruitment
                    along with the associated statutory compliance is not
                    exactly the best answer of these situations.
                </p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="text-align: left;
            text-justify: inter-word;">
                <p>
                    <span class="greenColor" style="font-size: 18px; font-weight: bold;">
                        Zipgigz offers an efficient way</span> to address the IT recruitment dilemma and to plug the
                    resource
                    gap in a cost effective manner in the form project based IT
                    staffing for mainstream tech skills
                    as well as niche and emerging skills. Zipgigz specializes in IT
                    and software temporary staffing
                    and helps recruiters with a quickly available tech talent
                    pool, empowering them with what today's
                    organisations need the most - agility.
                    We help IT firms and tech enabled businesses to quickly
                    scale-up or scale-down depending on their business cycles
                    without any long-term financial
                    implication.

                </p>
                <p>
                    This not only reduces overall staffing cost and
                    statutory compliance related work, but helps save
                    the most important cost of it all -opportunity
                    cost of not having the team in place within time.
                </p>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="text-align: left;
            text-justify: inter-word;">
                <p>
                    <span style="font-size: 18px; font-weight: bold;">
                        With a clear understanding of</span> present-day tech skills,
                    roles and responsibilities,
                    our domain of specialization includes web, software and
                    mobile related skill-sets such as Java, J2EE,
                    Python, C, C++, PHP, Wordpress, Laravel, HTML, CSS,
                    Angular JS, Nodejs, React JS, React Native,
                    Express JS, SQL, MongoDB, AWS, Azure, Android, ios,
                    DevOps, Selenium etc and niche technology skill
                    such as AI, Machine Learning, Data Analytics,
                    Blockchain, NLP, IOT, TensorFlow, DialogFlow, etc.

                </p>
                <p>
                    The typical resource gaps which we plug in are for the
                    roles of Software Developer, Java Developer, Front End Developer,
                    Backend Developer, Full Stack Developer, DevOps engineer,
                    Cloud specialist, UI Developer, MERN Developer,
                    Android Developer and such.
                </p>
            </div>

        </div>

    </div>
</div>
<div class="footer">
    <div class="container">
        <div class="row" style="padding-top: 50px;">
            <div class="col-md-2 col-lg-2">
                <ul style="list-style-type: none;">
                    <li> <img src="assets/zipgigz text white.png"></li>
                    <li><label style="font-size: 12px; padding-left: 15px;opacity: 0.9;">©2020 ZIPGIGZ Private<br>
                            Limited</label></li>
                </ul>
            </div>
            <div class="col-md-2 col-lg-2">
                <ul style="list-style-type: none; padding-top: 20px;">
                    <li>
                        <label style="font-size: 14px; cursor: pointer;opacity: 0.9;" (click)="policyClick()">Privacy
                            policy</label>
                    </li>
                    <li>
                        <label style="font-size: 14px; cursor: pointer;opacity: 0.9;" (click)="termsClick()">Terms &
                            Conditions</label>
                    </li>
                </ul>
            </div>
            <div class="col-md-2 col-lg-2">
                <ul style="list-style-type: none;padding-top: 20px;">
                    <li><label style="opacity: 0.9; font-size: 14px;"><i class="fa fa-envelope-o"
                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;contact@zipgigz.com</label></li>
                    <li><label style="opacity: 0.9; font-size: 14px;"><i class="fa fa-phone"
                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;022
                            &nbsp;4897&nbsp;2828&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label></li>
                    <li> <a href="https://www.linkedin.com/company/zipgigz"
                            style="outline: none;text-decoration: none; color:#FFFFFF;font-size: 14px;opacity: 0.9;">
                            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                            &nbsp;&nbsp;Connect with us
                        </a></li>
                </ul>
            </div>
            <div>

            </div>
            <div class="col-md-2 col-lg-3">
                <div class="d-flex flex-row" style="padding-top: 20px;">
                    <i style="padding-top: 3px;" class="fa fa-map-marker" aria-hidden="true"></i>
                    <label style="opacity: 0.9; font-size: 14px;padding-left: 5px;">
                        224, B wing, Excellencia<br> Lodha Supremus 2, Wagle estate, <br>
                        Thane West, Maharashtra. <br>400604
                    </label>
                </div>

            </div>
            <div class="col-md-2 col-lg-3">
                <form class="form-inline" style="padding-top: 15px;">
                    <label style="opacity: 0.9;">Subscribe to our newsletter</label>
                    <div class="input-group" style="padding-top: 10px;">
                        <input type="text" class="form-control" id="1" name="email" (click)="loseFocus()" (focus)="notFocused = false" (focusout)="notFocused = true" [(ngModel)]="subscribe.email" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"  #sEmail="ngModel" style="background-color:transparent;"
                            placeholder="Email Address">
                            
                        <div class="input-group-prepend">
                            <div class="input-group-text" style="background-color: white;">
                                <span style="cursor: pointer;" (click)="sendEmail()">OK</span>
                            </div>

                        </div>
                    </div>
                    <span style="font-family: zipGigzContent;font-size: 14px;" *ngIf="sEmail.invalid && notFocused" class="invalid">
                        Invalid Email
                      </span>
                      <div *ngIf="showMsg">
                        <span style="font-family: zipGigzContent;font-size: 14px;" >
                           Subscribed Successfully
                          </span>
                      </div>
                      <div *ngIf="showErr">
                        <span style="font-family: zipGigzContent;font-size: 14px;" >
                            Please try again later
                          </span>
                      </div>
                      <div *ngIf="showWarning">
                        <span style="font-family: zipGigzContent;font-size: 14px;" >
                            Email is mandatory
                          </span>
                      </div>
                </form>
            </div>



        </div>
    </div>