<div *ngIf="feedbackDone && feedback">
    <h3>Feedback already submitted for this week</h3>
</div>

<div *ngIf="!feedbackDone && feedback" style="    max-width: 700px;
margin: auto;
padding: 20px;">
    <h3 class="lblInputs"
        style="font-size:16px;text-align: center;margin-bottom: 0px;color: white;background-color: #67BC60;height: 100px;line-height: 100px;">
        Performance Review</h3>
    <div style="padding:10px 50px;border:1px solid gainsboro">
        <div style="display: flex;margin-bottom: 20px;">
            <img src={{review.candidateImgUrl}} style="width: 50px;height:50px;border-radius: 50%;">
            <div>
                <div>
                    <label class="lblInputs" style="margin-bottom: 0px;"><span class="opc">of</span>
                        {{review.firstName}} {{review.lastName}} </label>
                </div>
                <div>
                    <label class="lblInputs"><span class="opc">for period </span>{{review.from | date:'dd-MM-yyyy'}}
                        <span class="opc">to</span> {{review.to | date:'dd-MM-yyyy'}}</label>
                </div>
            </div>

        </div>
        <div style="display: flex;justify-content: space-evenly;margin-bottom: 15px;">
            <label class="lblInputs">CANDIDATE ID <span class="opc">{{review.candidateId }} </span></label>
            <label class="lblInputs">DEPLOYMENT ID <span class="opc">{{review.deploymentId }}</span></label>
        </div>
        <form [formGroup]="form" (ngSubmit)="submitFeedback()">
            <div style="margin-bottom: 15px;">
                <label class="black required lblInputs" for="cName" style="margin-right: 20px;">Performance
                    Rating</label>
                <ngb-rating name="stars" class="customRatingStar" [max]="5" formControlName="performance"></ngb-rating>
                <label class="red" *ngIf=" !form.controls.performance.valid && formSubmitted">
                    Performance rating is required.
                </label>
            </div>

            <div style="display: flex;">
                <div>
                    <label class="black required lblInputs" for="cName" style="margin-right: 20px;">Behavior
                        Rating</label>
                    <ngb-rating name="stars" class="customRatingStar" [max]="5" formControlName="behavior"></ngb-rating>
                    <label class="red" *ngIf=" !form.controls.behavior.valid && formSubmitted">
                        Behavior Rating is required.
                    </label>
                </div>
                <div style="padding-left: 100px;">
                    <label class="black lblInputs" for="cName" style="margin-bottom: 0px;">Applaud</label>
                    <div>
                        <img src="assets/image/clap.svg" style="cursor: pointer;" width="30px" height="30px"
                            (click)="checkbox.click()">
                        <label>{{form.controls.applause.value? 1 : 0}}</label><input type="checkbox"
                            style="display: none;" #checkbox formControlName="applause">
                    </div>

                </div>
            </div>




            <div>
                <label class="black required lblInputs" for="cName" style="margin-right: 20px;">Attendance
                    Rating</label>
                <ngb-rating name="stars" class="customRatingStar" [max]="5" formControlName="attendance"></ngb-rating>
                <label class="red" *ngIf="!form.controls.attendance.valid && formSubmitted">
                    Attendance Rating is required.
                </label>
            </div>


            <div>

                <label class="black lblInputs">Comments</label>
                <div>

                    <textarea rows="3" style="width: 100%; resize: none;outline: none;" formControlName="comments"
                        required placeholder="Enter comments here"></textarea>
                    <label class="red" *ngIf="!form.controls.comments.valid && formSubmitted">
                        Comments required.
                    </label>
                </div>
            </div>



            <input type="checkbox" style="display: none;" #checkbox formControlName="applause">

            <div class="form-group input col-lg-5 col-md-5 col-5">
                <label class="black lblInputs required" for="location">Number of working days</label><br>
                <ng-select text-overflow: ellipsis bindLabel="name" [virtualScroll]="true"
                    formControlName="noOfWorkingDays" appendTo="body" required [searchable]="true" [multiple]="false"
                    [clearable]="true" maxSelectedItems=5 name="location" placeholder="Select number of days">
                    <ng-option [value]="day" *ngFor="let day of days">
                        {{day}}
                    </ng-option>
                </ng-select>
                <label class="red" *ngIf="!form.controls.noOfWorkingDays.valid && formSubmitted">
                    Number of working days required.
                </label>
            </div>

            <div class="form-group input col-lg-5 col-md-5 col-5">
                <label class="black lblInputs required" for="location">Number of leaves availed this period</label><br>
                <ng-select text-overflow: ellipsis bindLabel="name" [virtualScroll]="true" formControlName="noOfLeaves"
                    appendTo="body" required [searchable]="true" [multiple]="false" [clearable]="true"
                    maxSelectedItems=5 name="location" placeholder="Select number of days">
                    <ng-option [value]="day" *ngFor="let day of days ">
                        {{day}}
                    </ng-option>
                </ng-select>
                <label class="red" *ngIf="!form.controls.noOfLeaves.valid && formSubmitted">
                    Number of leaves availed required.
                </label>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;">
                <button class="nextBtn btn">Submit</button>
            </div>

        </form>
    </div>
</div>


<!-- <div *ngIf="!feedback">
    <h3>
        Feedback not found.
    </h3>
</div> -->