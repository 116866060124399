import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor() { }
  showSelectedClient: boolean = true;
  showSelectedBenefitSection: boolean = true;
  ngOnInit(): void {
  }

  toggleClientClick() {
    this.showSelectedClient = !this.showSelectedClient;
  }

  toggleBenefitsSection() {
    this.showSelectedBenefitSection = !this.showSelectedBenefitSection;
  }
}
