import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmUserComponent } from './confirm-user/confirm-user.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { PartnerRegistrationComponent } from './home/partner-registration/partner-registration.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { RequirementComponent } from './home/requirement/requirement.component';
import { LandingComponent } from './landing/landing.component';


const routes: Routes = [
  { path: "feedback/:id", component: FeedbackComponent },
  // { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: "requirement", component: RequirementComponent },
  { path: "registration", component: RegistrationComponent },
  { path: "partner", component: PartnerRegistrationComponent },
  { path: "confirmUser", component: ConfirmUserComponent },
  { path: "landing", component: LandingComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
