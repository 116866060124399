import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequirementComponent } from './home/requirement/requirement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './home/registration/registration.component';
import { PartnerRegistrationComponent } from './home/partner-registration/partner-registration.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpBaseURLInterceptor } from './core/interceptor/http.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { FeedbackComponent } from './feedback/feedback.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmUserComponent } from './confirm-user/confirm-user.component';
import { LandingComponent } from './landing/landing.component';

const routes=[
  {
  path:'home', component:HomeComponent
}]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RequirementComponent,
    RegistrationComponent,
    PartnerRegistrationComponent,
    FeedbackComponent,
    ConfirmUserComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    FormsModule,
    Ng2PageScrollModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes,{
      anchorScrolling: 'enabled',
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: true,
      progressAnimation: "increasing",
      preventDuplicates: true,
      positionClass: "toast-top-center"
    }),
    NgbModule,

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpBaseURLInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
