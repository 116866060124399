import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscribe } from 'src/app/core/dataObjects/subscribe';
import { NotificationServiceService } from 'src/app/core/notification/notification-service.service';
import { HomeService } from '../home.service';

@Component({
  selector: 'app-partner-registration',
  templateUrl: './partner-registration.component.html',
  styleUrls: ['./partner-registration.component.css']
})
export class PartnerRegistrationComponent implements OnInit {

  constructor(private homeService: HomeService, private router: Router, private notifyService: NotificationServiceService) { }
  subscribe: Subscribe = new Subscribe();
  showMsg:boolean=false;
  showErr:boolean=false;
  showWarning:boolean=false;
  notFocused = false;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showMsg=false;
    this.showErr=false;
    this.showWarning=false;
  }

  ngAfterContentInit() {
    hbspt.forms.create({
      portalId: "8481938",
      formId: "08aa049b-4274-47d9-be4a-48e50700d4f2",
      target: "#hubspotForm"
    });
  }

  loseFocus() {
    this.showMsg=false;
    this.showErr=false;
    this.showWarning=false;
  }
  
  termsClick() {
    window.open('assets/terms.pdf');
  }

  policyClick() {
    window.open('assets/policy.pdf');
  }
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  sendEmail() {
    if (this.subscribe.email == '' || this.subscribe.email == undefined) {
      console.log(this.subscribe.email);
      this.showErr=false;
      this.showMsg=false;
      return this.showWarning=true;
    }
    if (this.subscribe.email.match(this.regexp)) {
    this.homeService.sendEmail(this.subscribe).subscribe(data => {
      console.log("success" + this.subscribe);
      // this.notifyService.showSuccess("Subcribed Successfully", "Success");
      this.subscribe.email = '';
      this.showMsg=true;
      this.showErr=false;
      this.showWarning=false;


    },
      err => {
        console.log("error" + err);
        this.subscribe.email = '';
        this.showErr=true;
        this.showWarning=false;
        this.showMsg=false;
        // this.notifyService.showError("Please try again later", "Error");
      })
    }
  }
}
