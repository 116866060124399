import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable()
export class HttpBaseURLInterceptor implements HttpInterceptor {
    constructor(private injector: Injector,
        private router: Router) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // This is refresh token call
        const commonParams = {};
        req = req.clone({
            setParams: {
                ...commonParams
            }
        });
        // if (req.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        //     return next.handle(req);
        // }
        //console.log(environment.api_base_url);

        const apiReq = req.clone({ url: `${environment.api_base_url}${req.url}` });
        return next.handle(apiReq);

    }


}