<header id="home">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="#">
                <img src="assets/logo.png" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <div class="mr-auto"></div>
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/home">Home <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" [fragment]="'Zipgigz'">Why Zipgigz</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" [fragment]="'Works'">How It Works</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" [fragment]="'Partner'">Partner With Us</a>
                    </li>
                </ul>
            </div>
        </nav>

    </div>
</header>

<div class="main">
    <div class="section-1 container">
        <div class="row row1">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h2>Register for<br> Tech Gigs</h2>
                <p class="pt-2">
                    If you are an IT / Software professional,<br>register with us for contract jobs or projects.
                </p>
                <img class="imgSec-1" src="assets/software developer.svg">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-6 class regForm" id="hubspotForm2" style=" z-index: 9;">

            </div>

        </div>
    </div>
    <div class="footer">
        <div class="container">
            <div class="row" style="padding-top: 160px;">
                <div class="col-md-2 col-lg-2">
                    <ul style="list-style-type: none;">
                        <li> <img src="assets/zipgigz text white.png"></li>
                        <li><label style="font-size: 12px; padding-left: 15px;opacity: 0.9;">©2020 ZIPGIGZ Private<br>
                                Limited</label></li>
                    </ul>
                </div>
                <div class="col-md-2 col-lg-2">
                    <ul style="list-style-type: none; padding-top: 20px;">
                        <li>
                            <label style="font-size: 14px; cursor: pointer;opacity: 0.9;"
                                (click)="policyClick()">Privacy
                                policy</label>
                        </li>
                        <li>
                            <label style="font-size: 14px; cursor: pointer;opacity: 0.9;" (click)="termsClick()">Terms &
                                Conditions</label>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-lg-2">
                    <ul style="list-style-type: none;padding-top: 20px;">
                        <li><label style="opacity: 0.9; font-size: 14px;"><i class="fa fa-envelope-o"
                                    aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;contact@zipgigz.com</label></li>
                        <li><label style="opacity: 0.9; font-size: 14px;"><i class="fa fa-phone"
                                    aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;022
                                &nbsp;4897&nbsp;2828&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label></li>
                        <li> <a href="https://www.linkedin.com/company/zipgigz"
                                style="outline: none;text-decoration: none; color:#FFFFFF;font-size: 14px;opacity: 0.9;">
                                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                &nbsp;&nbsp;Connect with us
                            </a></li>
                    </ul>
                </div>
                <div>

                </div>
                <div class="col-md-2 col-lg-3">
                    <div class="d-flex flex-row" style="padding-top: 20px;">
                        <i style="padding-top: 3px;" class="fa fa-map-marker" aria-hidden="true"></i>
                        <label style="opacity: 0.9; font-size: 14px;padding-left: 5px;">
                            224, B wing, Excellencia<br> Lodha Supremus 2, Wagle estate, <br>
                            Thane West, Maharashtra. <br>400604
                        </label>
                    </div>

                </div>
                <div class="col-md-2 col-lg-3">
                    <form class="form-inline" style="padding-top: 15px;">
                        <label style="opacity: 0.9;">Subscribe to our newsletter</label>
                        <div class="input-group" style="padding-top: 10px;">
                            <input type="text" class="form-control" id="1" name="email" [(ngModel)]="subscribe.email" (click)="loseFocus()" (focus)="notFocused = false" (focusout)="notFocused = true" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"  #sEmail="ngModel" style="background-color:transparent;"
                                placeholder="Email Address">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="background-color: white;">
                                    <span style="cursor: pointer;" (click)="sendEmail()">OK</span>
                                </div>

                            </div>
                        </div>
                        <span style="font-family: zipGigzContent;font-size: 14px;"  *ngIf="sEmail.invalid && notFocused" class="invalid">
                            Invalid Email
                          </span>
                          <div *ngIf="showMsg">
                            <span style="font-family: zipGigzContent;font-size: 14px;" >
                               Subscribed Successfully
                              </span>
                          </div>
                          <div *ngIf="showErr">
                            <span style="font-family: zipGigzContent;font-size: 14px;" >
                                Please try again later
                              </span>
                          </div>
                          <div *ngIf="showWarning">
                            <span style="font-family: zipGigzContent;font-size: 14px;" >
                                Email is mandatory
                              </span>
                          </div>
                    </form>
                </div>



            </div>
        </div>

    </div>
</div>