import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Zipgigz – Hire dedicated Tech Talent for short term';
  constructor(private titleService: Title, private metaService: Meta) {}
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Top Tech talent for Temporary and project-based IT staffing. Web, software, Android, iOS developers and more. Free onboarding after 6 billing months.'},
    ]);
  }
}
