import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { Subscribe } from '../core/dataObjects/subscribe';
import { NotificationServiceService } from '../core/notification/notification-service.service';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  viewersArray: [];
  viewersArray2: [];
  subscribe: Subscribe = new Subscribe();
  showMsg: boolean = false;
  showErr: boolean = false;
  showWarning: boolean = false;
  notFocused = false;
  urlPath=environment.domain_name;
  constructor(private homeService: HomeService, private router: Router, private notifyService: NotificationServiceService) { }

  ngOnInit(): void {
    // this.homeService.getViewersList().subscribe(data=>{
    //   this.viewersArray=data.slice(0,3);
    //   this.viewersArray2=data.slice(3,6);
    //   console.log(data);
    // })
    // When the user scrolls the page, execute myFunction
    window.onscroll = function () { myFunction() };

    // Get the header
    var header = document.getElementById("home");

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

    this.showMsg = false;
    this.showErr = false;
    this.showWarning = false;
  }
  loseFocus() {
    this.showMsg = false;
    this.showErr = false;
    this.showWarning = false;
  }

  getSchedule() {

    window.location.href = "https://calendly.com/gigz/20min";
    //this.router.navigate(["/calendar", {}]);
  }
  getStarted() {
    this.router.navigate(["/requirement", {}]);
  }

  registerForGigs() {
    this.router.navigate(["/registration", {}]);
  }

  getPartner() {
    this.router.navigate(["/partner", {}]);
  }

  termsClick() {
    window.open('assets/terms.pdf');
  }

  policyClick() {
    window.open('assets/policy.pdf');
  }
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  sendEmail() {
    if (this.subscribe.email == '' || this.subscribe.email == undefined) {
      console.log(this.subscribe.email);
      this.showErr = false;
      this.showMsg = false;
      return this.showWarning = true;
    }

    if (this.subscribe.email.match(this.regexp)) {

      this.homeService.sendEmail(this.subscribe).subscribe(data => {
        console.log("success" + this.subscribe);
        // this.notifyService.showSuccess("Subcribed Successfully", "Success");
        this.subscribe.email = '';
        this.showMsg = true;
        this.showErr = false;
        this.showWarning = false;

      }
        ,
        err => {
          console.log("error" + err);
          this.subscribe.email = '';
          this.showErr = true;
          this.showWarning = false;
          this.showMsg = false;
          // this.notifyService.showError("Please try again later", "Error");
        })
    }
  }

  goToVendorSignup() {
    window.location.href = environment.domain_name + '/Login/#/signUp/vendor'
  }

  goToCompanySignUp() {
    window.location.href = environment.domain_name + '/Login/#/available-candiates'
  }
  goToLogin() {
    window.location.href = environment.domain_name + '/Login/#/login'
  }
  goToCommonSignUp() {
    window.location.href = environment.domain_name + '/Login/#/signUpCommon'
  }

}
