<header>
    <div class="container">
        <nav class="navbar navbar-expand-lg" style="padding: 0;">
            <a class="navbar-brand">
                <img src="assets/logo.png" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link">How is Works</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Why?</a>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="btn btn-signUp">Sign Up</button>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="btn btn-login">Log In</button>
                    </li>
                </ul>
            </div>
        </nav>
    </div>

</header>
<section class="section-1">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 mt-5">
                <label class="headerLbl">The Future of Hiring<br> <img class="imgOval"
                        src='assets/landing/oval.svg'>Distributed Tech Workforce</label><br>
                <p class="content">Zipgigz is a B2B platform where Technology Businesses<br> can discover and hire tech
                    talent on contract through our<br> network of Talent Partners across geographies.</p><br>
                <div>
                    <button type="button" class="btn btn-getStarted">Hire Tech Talent</button>
                    &nbsp;&nbsp;or&nbsp;&nbsp;
                    <button type="button" class="btn btn-talent">Become a Talent Partner</button>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <img src="assets/landing/personWithSkills.svg" alt="">
            </div>
        </div>
    </div>

</section>
<section class="section-2">
    <div class="container" style="background-color: #f6f6f6;"
        [ngStyle]="{'min-height': showSelectedClient == true?'330px':'350px'}">
        <h2>How it <img class="imgOval" src='assets/landing/oval.svg'> Works</h2>
        <div class="row pt-5" *ngIf="showSelectedClient" style="cursor: pointer;" (click)="toggleClientClick()">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div>
                    <img style="position: absolute;" src="assets/landing/selected bg figure.svg">
                    <img style="position: absolute;" src="assets/landing/selected top figure.svg">
                    <label class="lblClient">For Clients</label>
                    <p class="lblText">Hire Tech Talent on contract with full-time engagement</p>
                </div>
                <div style="margin-left: 48%;">
                    <img style="position: absolute;margin: 0px;padding:0px;" src="assets/landing/not selected.svg"
                        width="500px">
                    <label class="lblTalent">For Talent Partners</label>
                </div>
            </div>
        </div><br>
        <div class="row pt-5" *ngIf="!showSelectedClient" style="cursor: pointer;" (click)="toggleClientClick()">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div>
                    <img style="position: absolute;margin: 0px;padding:0px;" src="assets/landing/not selected.svg"
                        width="500px">
                    <label class="lblClient1">For Clients</label>
                    <p class="lblText1">Hire Tech Talent on contract with full-time<br> engagement</p>
                </div>
                <div style="margin-left: 42%;">
                    <img style="position: absolute;" src="assets/landing/selected bg figure.svg">
                    <img style="position: absolute;" src="assets/landing/selected top figure.svg">
                    <label class="lblTalent1">For Talent Partners</label>
                </div>
            </div>
        </div><br>
    </div>
    <div class="container" style="background-color: #FFFFFF;">
        <div style="margin-left: 50%;position: relative;">
            <img style="position: absolute;z-index: 99999;" src="assets/landing/end point.svg">
            <img style="position: absolute;margin-left: 5%;padding-top: 20px;" src="assets/landing/Rectangle 17.svg">
        </div>
        <div class="row pt-5" *ngIf="showSelectedClient">
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 100px;">
                <img style="margin-left: 40%;" src="assets/landing/NumberOne.PNG"><br>
                <label class="postVacancy">Post Vacancies</label><br>
                <p class="postVacancySub">Your vacancies are immediately accessible to our entire Talent Partner
                    network.</p><br>
            </div>

            <div class="col-sm-6 col-md-6 col-sm-6">
                <img style="position: absolute;margin-left: 55%;" src="assets/landing/1_rec_1.svg">
                <img style="position: absolute;margin-left: 70%;" src="assets/landing/1_rec_2.svg">
                <img style="margin-left: 10%;position: absolute;padding-top: 50px;"
                    src="assets/landing/client 1_pic.png">
            </div>

            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 100px;">
                <img style="position: absolute;margin-top: 50px;" src="assets/landing/2_rec_1.png">
                <img style="position: absolute;margin-left: 2%;" src="assets/landing/2_pic.png">
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 100px;">
                <img style="margin-left: 45%;" src="assets/landing/NumberTwo.PNG"><br>
                <label class="suggestCand">Evaluate Suggested Candidates</label><br>
                <p class="postVacancySub">We suggest profiles based on skill match.
                    You can also manually browse and
                    shortlist candidates.</p><br>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 100px;">
                <img style="margin-left: 40%;" src="assets/landing/NumberThree.PNG"><br>
                <label class="postInterview">Interview with Ease</label><br>
                <p class="postVacancySub">Seamlessly schedule and join secured
                    meetings through Zipgigz. No need to set
                    up the event separately through other
                    channels</p><br>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6">
                <img style="margin-left: 10%;position: absolute;padding-top: 50px;"
                    src="assets/landing/scheduleInterview.png">
                <div style="position: relative;z-index: -1;">
                    <img style="position: absolute;margin-left: 75%;margin-top: 50%;" src="assets/landing/1_rec_1.svg">
                    <img style="position: absolute;margin-left: 85%;margin-top: 50%;" src="assets/landing/1_rec_2.svg">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 200px;">
                <img style="position: absolute;padding-top: 100px;" src="assets/landing/4_rec_1.svg">
                <img style="margin-left: 5%;position: absolute;padding-top: 50px;" src="assets/landing/4_pic.svg">
                <div style="position: absolute;margin-left: 60%;margin-top: 40%;">
                    <img style="position: absolute;z-index: -1;" src="assets/landing/4_rec_2.svg">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 200px;">
                <img style="margin-left: 45%;" src="assets/landing/NumberFour.PNG"><br>
                <label class="deploy">Hire and Deploy</label><br>
                <p class="postVacancySub">Hire selected candidates online based on
                    precise Scope-of-Work creation with
                    weekly performance tracking and monthly
                    invoicing.</p><br>
            </div>
        </div>
        <div class="row pt-5" *ngIf="!showSelectedClient">
            <div class="col-sm-6 col-md-6 col-sm-6">
                <img style="margin-left: 40%;" src="assets/landing/NumberOne.PNG"><br>
                <label class="postVacancy">Browse Vacancies</label><br>
                <p class="postVacancySub">Identify vacancies of interest based on
                    skills which you can cater to.
                </p><br>
            </div>

            <div class="col-sm-6 col-md-6 col-sm-6">
                <img style="position: absolute;margin-left: 55%;" src="assets/landing/1_rec_1.svg">
                <img style="position: absolute;margin-left: 70%;" src="assets/landing/1_rec_2.svg">
                <img style="margin-left: 10%;position: absolute;padding-top: 50px;" src="assets/landing/1_pic.png">
            </div>

            <div class="col-sm-6 col-md-6 col-sm-6 mt-5" style="padding-top: 150px;">
                <img style="position: absolute;margin-top: 50px;" src="assets/landing/2_rec_1.png">
                <img style="position: absolute;margin-left: 2%;" src="assets/landing/2_picTalent.png">
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6 mt-5" style="padding-top: 150px;">
                <img style="margin-left: 45%;" src="assets/landing/NumberTwo.PNG"><br>
                <label class="cand">List your Candidates</label><br>
                <p class="postVacancySub">Create comprehensive candidate profiles
                    with information about skills, domain and
                    projects for easy discovery of your
                    candidate profiles by clients.</p><br>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 150px;">
                <img style="margin-left: 40%;" src="assets/landing/NumberThree.PNG"><br>
                <label class="postInterview">Coordinate Interviews</label><br>
                <p class="postVacancySub">Accept or reject interview requests based
                    on candidate availability. You can also
                    participate in interview meetings to ensure
                    things go smoothly.
                </p><br>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 150px;">
                <img style="margin-left: 10%;position: absolute;padding-top: 50px;" src="assets/landing/3-pic.png">
                <div style="position: relative;z-index: -1;">
                    <img style="position: absolute;margin-left: 65%;margin-top: 25%;" src="assets/landing/1_rec_1.svg">
                    <img style="position: absolute;margin-left: 65%;margin-top: 25%;" src="assets/landing/1_rec_2.svg">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 150px;">
                <img style="position: absolute;padding-top: 100px;" src="assets/landing/4_rec_1.svg">
                <img style="margin-left: 5%;position: absolute;padding-top: 50px;" src="assets/landing/4_pic.png">
                <div style="position: absolute;margin-left: 60%;margin-top: 40%;">
                    <img style="position: absolute;z-index: -1;" src="assets/landing/4_rec_2.svg">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-sm-6" style="padding-top: 150px;">
                <img style="margin-left: 45%;" src="assets/landing/NumberFour.PNG"><br>
                <label class="deploy">Get Paid Monthly for
                    Deployed Talent</label><br>
                <p class="postVacancySub">Manage deployments, monitor candidate
                    performance and manage monthly
                    invoicing with ease.</p><br>
            </div>
        </div>
        <img style="z-index: 99999;margin-top: 18%;margin-left: 50%;" src="assets/landing/end point.svg">
        <div class="d-flex align-items-center justify-content-center ml-5">
            <button type="button" class="btn discover">Get Started</button>
        </div>
        <div class="row pt-5">
            <div class="col-sm-3 col-md-3 col-lg-3">
                <label class="numberCount">1,50,000+</label><br>
                <label class="text">Customers who use landify UI Kit to build their new startup landing page.</label>
            </div>

            <div class="col-sm-9 col-md-9 col-lg-9">
                <div class="row">
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded ">
                            <img src="assets/landing/skill1.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill2.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill3.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill4.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill5.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded ">
                            <img src="assets/landing/skill6.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill7.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill8.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill9.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill10.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded ">
                            <img src="assets/landing/skill11.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill12.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded ">
                            <img src="assets/landing/skill13.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill14.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill15.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill16.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill17.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-md-1 ml-4 ml-4 mb-4">
                        <span class="shadow p-3 mb-5 bg-white rounded">
                            <img src="assets/landing/skill18.png" style="height: auto;width: 50px;">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-3">
    <div class="container" style="background-color: #f6f6f6;"
        [ngStyle]="{'min-height': showSelectedBenefitSection == true?'320px':'345px'}">
        <h2 class="benefits"><img class="imgOval" src='assets/landing/oval.svg'>Benefits</h2>
        <div class="row pt-5" *ngIf="showSelectedBenefitSection" style="cursor: pointer;"
            (click)="toggleBenefitsSection()">
            <img style="position: absolute;" src="assets/landing/line_part1.svg">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div>
                    <img style="position: absolute;" src="assets/landing/selected bg figure.svg">
                    <img style="position: absolute;" src="assets/landing/selected top figure.svg">
                    <label class="lblClient">For Clients</label>
                    <p class="lblText">Hire Tech Talent on contract with full-time engagement</p>
                </div>
                <div style="margin-left: 48%;">
                    <img style="position: absolute;margin: 0px;padding:0px;" src="assets/landing/not selected.svg"
                        width="500px">
                    <label class="lblTalent">For Talent Partners</label>
                </div>
            </div>
        </div><br>
        <div class="row pt-5" *ngIf="!showSelectedBenefitSection" style="cursor: pointer;"
            (click)="toggleBenefitsSection()">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div>
                    <img style="position: absolute;margin: 0px;padding:0px;" src="assets/landing/not selected.svg"
                        width="500px">
                    <label class="lblClient1">For Clients</label>
                    <p class="lblText1">Hire Tech Talent on contract with full-time<br> engagement</p>
                </div>
                <div style="margin-left: 42%;">
                    <img style="position: absolute;" src="assets/landing/selected bg figure.svg">
                    <img style="position: absolute;" src="assets/landing/selected top figure.svg">
                    <label class="lblTalent1">For Talent Partners</label>
                </div>
            </div>
        </div><br>
    </div>
    <div class="container" style="background-color: #FFFFFF;">
        <div class="row" style="margin-top: 100px;" *ngIf="showSelectedBenefitSection">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/fast 2.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Faster Deployments</label><br>
                <p class="content">Up to 4X faster turnaround times through
                    right skill match from an aggregated pool
                    of talent.</p>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/workflow 2.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Efficient Workflow</label><br>
                <p class="content">One place for all - candidate discovery,
                    communication with talent partners,
                    interviews and deployment tracking.
                    Seamless interconnectivity.</p>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-3">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/Hassle free billing.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Hassle-free
                    Billing</label><br>
                <p class="content">
                    No payroll and compliance liabilities.
                    Simple monthly invoicing at one place.
                </p>
            </div>

        </div>
        <div class="row" style="margin-top: 100px;" *ngIf="!showSelectedBenefitSection">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/business development 1.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Easy Business
                    Development</label><br>
                <p class="content">Grow faster by catering to an aggregated
                    pool of vacancies from our Client network.
                </p>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/interconnectivity 2A.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Efficient Workflow</label><br>
                <p class="content">Grow faster by catering to an aggregated
                    pool of vacancies from our Client network.
                </p>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-3">
                <span class="d-flex align-items-center justify-content-center">
                    <img src="assets/landing/billing cycle 1.svg" height="auto" width="80px"><br>
                </span>
                <label class="lblHeader d-flex align-items-center justify-content-center">Easy Billing</label><br>
                <p class="content">
                    Grow faster by catering to an aggregated
                    pool of vacancies from our Client network.

                </p>
            </div>

        </div>
    </div>
</section>
<section class="section-4">
    <div class="container">
        <h2 class="benefits d-flex align-items-center justify-content-center">What they say<img class="imgOval"
                src='assets/landing/oval.svg'></h2>
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <img style="position: absolute;" height="auto" width="360px"
                    src="assets/landing/bg rectangle - green.svg">
                <span style="position: absolute;margin-left: 40%;" class="pt-2 txtClient">Client</span>
                <label class="efficient">Efficient
                    Collaborating</label>
                <p style="position: absolute;padding-left: 80px;padding-top: 80px;z-index: 1;">Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Iure nulla odit vitae modi eligendi excepturi consequuntur
                    explicabo sed quibusdam deleniti, eos rerum repellendus nihil consectetur velit harum dolor mollitia
                    possimus!</p>
                <img style="position: absolute;padding-top: 30px;" height="auto" width="400px"
                    src="assets/landing/top retangle.svg">
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <img style="position: absolute;" height="auto" width="360px" src="assets/landing/bg rectangle.svg">
                <span style="position: absolute;margin-left: 40%;" class="pt-2 txtClient">Talent Partner</span>
                <label class="efficient">Intuitive Design</label>
                <p style="position: absolute;padding-left: 80px;padding-top: 80px;z-index: 1;">Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Iure nulla odit vitae modi eligendi excepturi consequuntur
                    explicabo sed quibusdam deleniti, eos rerum repellendus nihil consectetur velit harum dolor mollitia
                    possimus!</p>
                <img style="position: absolute;padding-top: 30px;" height="auto" width="400px"
                    src="assets/landing/top retangle.svg">
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <img style="position: absolute;" height="auto" width="360px"
                    src="assets/landing/bg rectangle - green.svg">
                <span style="position: absolute;margin-left: 40%;" class="pt-2 txtClient">Client</span>
                <label class="efficient">Mindblowing Service</label>
                <p style="position: absolute;padding-left: 80px;padding-top: 80px;z-index: 1;">Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Iure nulla odit vitae modi eligendi excepturi consequuntur
                    explicabo sed quibusdam deleniti, eos rerum repellendus nihil consectetur velit harum dolor mollitia
                    possimus!</p>
                <img style="position: absolute;padding-top: 30px;" height="auto" width="400px"
                    src="assets/landing/top retangle.svg">
            </div>

        </div>
        <div class="row" style="margin-top: 25%;">
            <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding-left: 70px;">
                <span>Jane Cooper</span><br>
                <span>CEO at ABC Corporation</span>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding-left: 70px;">
                <span>Jane Cooper</span><br>
                <span>CEO at ABC Corporation</span>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding-left: 70px;">
                <span>Jane Cooper</span><br>
                <span>CEO at ABC Corporation</span>
            </div>

        </div>
    </div>
</section>
<section class="section-5">
    <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3">
            <img src="assets/landing/left.png" style="height: auto;width:500px;">
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6">
            <h3>Get Started with the Future</h3>
            <p class="customP">of Mobilizing Distributed Tech Teams.</p>
            <button type="button" class="btn btn-signUp">Sign Up</button>
        </div>

        <div class="col-sm-3 col-md-3 col-lg-3">
            <img src="assets/landing/right.png" style="height: auto;width:500px;">
        </div>

    </div>


</section>
<footer class="footer">
    <div class="container">
        <img src="assets/logo.png" style="height: auto;width: 200px;" alt=""><br>
        <div class="footerTerms" style="display: inline;">
            <span>Terms & Conditions</span>
            <span>&nbsp;|&nbsp;</span>
            <span>Privacy Policy</span>
            <span>&nbsp;|&nbsp;</span>
            <span>©2020 ZIPGIGZ Private Limited</span>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
            <span class="btnConnect">
                Connect with us
            </span>
            <span class="btnConnect">
                Subscribe to our newsletter
            </span>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-8">
                <div>
                    <div style="display: inline;">
                        <img src="assets/landing/emailIcon.svg">
                        <span class="txtFooter">&nbsp;contact@zipgigz.com</span>
                        &nbsp;<img src="assets/landing/phoneIcon.svg">
                        &nbsp;<span class="txtFooter">&nbsp;022 4897 2828</span>
                        &nbsp;<img src="assets/landing/linkdlnIcon.svg">
                        <span class="txtFooter">&nbsp;Connect with us</span>
                    </div>
                </div>

            </div>

            <div class="col-sm-4 col-md-4 col-lg-4">
                <form class="form-inline" style="float: right;background-color: #57a844;">
                    <div class="input-group">
                        <input type="text" class="form-control" id="1" name="email"
                            style="background-color:transparent;" placeholder="Your email address">
                        <div class="input-group-prepend">
                            <div class="input-group-text" style="background-color: #57a844;border-radius: 5px;">
                                <span style="cursor: pointer;"><img src="assets/landing/iconSend.svg"></span>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </div>
        <div style="margin-top: 30px;">
            <div style="display: inline;">
                <img src="assets/landing/locationIcon.svg">
                <span class="txtFooter">&nbsp;224, B wing,Excellencia,Lodha Supremus 2, Wagle estate, Thane West,
                    Maharashtra.
                    400604</span>
            </div>
        </div>

    </div>

</footer>